import React, { useRef, useState, useCallback } from "react";
import Acnavbar from "../components/Acnavbar";
import ProductDescription from "../components/ProductDescription";
import fullTimeIcon from "../assets/images/fullTime.webp";
import allEmployees from "../assets/images/allEmployees.webp";
import Contact from "../components/Contact";
import Footer from "../components/footer";
import { Container } from "react-bootstrap";
import Seo from "../shared/seo";
import Wrapper from "../components/Wrapper";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import CareerInsideTemplateForm from "../templates/carrerInsideTemplateForm";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";

function careerInsideTemplate({ pageContext }: any) {
  const reCaptchaKey:any = process.env.RECAPTCHA_SITE_KEY
  return (
    <GoogleReCaptchaProvider
    children={true}
    reCaptchaKey={reCaptchaKey}
  >
    <Wrapper>
      <Seo
        title={pageContext.seo_title}
        description={pageContext.seo_description}
      />

      <div>
        <Acnavbar
          home={false}
          pathname={"careers"}
          logo={""}
          logoLogic={false}
        />
        <ProductDescription
          topheader="CAREERS"
          header={pageContext.role}
          headerText={pageContext.short_desc}
          showButton={false}
        />
        <div className="common-background bg-cover">
          <Container className="pt-4 py-lg-5 pb-3 d-flex flex-column flex-lg-row">
            <div className="text-white w-lg-50">
              <p className="fnt-28 fnt-sm-16 fw-700 mb-4">{pageContext.role}</p>
              <p className="fnt-16 fnt-sm-14">Bangalore, Karnataka, India </p>
              <div className="d-flex align-items-baseline">
                <div>
                  <LazyLoadImage
                    effect="blur"
                    src={fullTimeIcon}
                    alt="FullTime Icon"
                  />
                </div>
                <p className="ms-3 fnt-sm-16">Full time (On Site)</p>
              </div>
              <div className="d-flex align-items-baseline">
                <div>
                  <LazyLoadImage
                    effect="blur"
                    src={allEmployees}
                    alt="All Employees"
                  />
                </div>
                <p className="mb-4 pb-2 ms-3 fnt-sm-16">
                  50+ employees in
                  <span className="ms-2 fw-bold">IT service</span>
                </p>
              </div>
              <p className="fnt-24 fnt-sm-16 fw-700">About the Job</p>
              <p className="font-monospace fnt-sm-12">{pageContext.about}</p>
              <p className="fw-600 fnt-sm-16">Introduction</p>
              <p className="text-white fnt-16 mb-4 pb-2 fnt-sm-14">
                {pageContext.details}
              </p>
              <p className="text-white fnt-18 fw-600 mb-4 fnt-sm-16">
                Responsibilities:{" "}
              </p>
              <div className="px-4 fnt-sm-14">
                <ul>
                  {pageContext.Responsibilities.map(
                    (skill: any, index: any) => (
                      <li key={index}>{skill}</li>
                    )
                  )}
                </ul>
              </div>
              <p className="text-white fnt-18 fw-600 mb-4 fnt-sm-16">
                Required Technical and Professional Expertise
              </p>
              <div className="px-4 fnt-sm-14">
                <ul>
                  {pageContext.requiredTechSkills.map(
                    (skill: any, index: any) => (
                      <li key={index}>{skill}</li>
                    )
                  )}
                </ul>
              </div>
              <div className="fnt-sm-14">
                <p className="text-white">{pageContext.bottom_desc}</p>
              </div>
            </div>
            <CareerInsideTemplateForm  pageContext ={pageContext} />
          </Container>
        </div>
        <div className="bg-white">
          <Contact />
        </div>

        <Footer />
      </div>
    </Wrapper>
    </GoogleReCaptchaProvider>
  );
}

export default careerInsideTemplate;
